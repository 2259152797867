import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationRU from '../locales/ru.json';
import translationEN from '../locales/en.json';

// the translations
const resources = {
  ru: {
    translation: translationRU
  },
  en: {
    translation: translationEN
  },
};

i18next
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    resources,

  });

export default i18next;