import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import theme from './theme';
import './utils/i18next'
import { LanguageContextProvider } from "./context/LanguageContext";
import InfoProvider from './provider/InfoProvider';
process.env.TZ = 'Europe/Moscow';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LanguageContextProvider>
        <InfoProvider>
          <App />
        </InfoProvider>
      </LanguageContextProvider>
    </ThemeProvider>
  </Router>
);
