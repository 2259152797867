import React, {
  createContext,
  useContext,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";

export const LanguageContext = createContext(undefined);


const languages = [
  {
    nativeName: 'Русский',
    code: 'ru'
  },
  {
    nativeName: 'English',
    code: 'en'
  }
]

export const LanguageContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18n',lng)
  }
  useEffect(()=>{
    let code = localStorage.getItem('i18n') 
    if(code){
      changeLanguage(code)
    }
  },[])
  
  return (
    <LanguageContext.Provider
      value={{ t, i18n, changeLanguage, languages }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);